import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Input, Button, Loader, Typography, InputDate, Select } from '@bayon/commons';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import moment from 'moment';
import Orgao from '../../components/orgao/Orgao';
import Situacao from '../../components/situacao/Situacao';
import * as actions from './PesquisaProcesso.epics';
import Alerts from '../../components/alerts/Alerts';
import { SITE_KEY, AMBIENTE } from '../../commons/constants';
import { formatarNumeroMP } from '../../commons/mascaras';
import * as alertActions from '../../components/alert/Alert.epics';
import { getTextoCabecalho, tiposProcessos } from '../../utils/utils';
import * as config from '../../utils/configs';
import ConsultaProcesso from '../consultaProcesso/ConsultaProcesso';
import { exibeResultadosMesmaPagina } from '../../utils/funcoesConsultaProcesso';
import { setPaginaAtual as setPaginaAtualAction } from '../consultaProcesso/ConsultaProcesso.epics';

const styles = () => ({
  colorPadding: { paddingTop: 20, paddingLeft: 20, paddingRight: 20, backgroundColor: '#F2F2F2' },
  colorDisableDate: { backgroundColor: '#EAEAEA' },
});

class PesquisaProcesso extends Component {
  constructor(props) {
    super(props);
    const { match } = props;

    let tipoRelatorio = 1;
    switch (match.url.toLowerCase()) {
      case '/portaltransparencia/inqueritocivil': tipoRelatorio = 2; break;
      case '/portaltransparencia/procedimentoinvestigatorio': tipoRelatorio = 3; break;
      case '/portaltransparencia/inqueritopolicial': tipoRelatorio = 4; break;
      case '/portaltransparencia/termoajustamentoconduta': tipoRelatorio = 5; break;
      case '/portaltransparencia/recomendacoesexpedidas': tipoRelatorio = 6; break;
      case '/portaltransparencia/resolucao-89': tipoRelatorio = 7; break;
      case '/portaltransparencia/termoajustamentoconduta-resolucao-2-2011': tipoRelatorio = 8; break;
      case '/portaltransparencia/inqueritocivil-resolucao-2-2011': tipoRelatorio = 9; break;
      case '/portaltransparencia/consulta-distribuicao-110-2014': tipoRelatorio = 10; break;
      case '/portaltransparencia/audienciaspublicas-resolucao-92': tipoRelatorio = 11; break;
      default: tipoRelatorio = 1;
    }

    localStorage.setItem('tipoRelatorio', tipoRelatorio);

    this.state = {
      erroInputNumeroMP: false,
      captcha: '',
      tipoRelatorio,
      numeroMP: '',
      numeroMPFormatado: '',
      cdOrgao: '',
      cdSituacao: '',
      parteInteressada: '',
      desabilidado: false,
      dataInicial: '',
      dataFinal: '',
      valorOrgao: { value: '', label: 'Selecione um órgão' },
      valorSituacao: { value: '', label: 'Selecione uma situação' },
      tipoProcesso: 0,
      tipoProcessoSelecionado: true,
    };
  }

  componentDidMount = () => {
    const { processos, fetchConfiguracoes } = this.props;
    fetchConfiguracoes();

    if ((!processos.length) && exibeResultadosMesmaPagina(this.state.tipoRelatorio)) {
      this.getProcessos();
    }
  }

  handleChange = value => this.setState({ captcha: value });

  getProcessos = async () => {
    const { history, fetchPesquisaProcesso, openAlertError, closeAlert, setPaginaAtual } = this.props;
    const { captcha, erroInputNumeroMP, tipoRelatorio, numeroMP, cdOrgao, cdSituacao, parteInteressada, dataInicial, dataFinal, tipoProcesso } = this.state;
    const newDataInicial = dataInicial ? new Date(dataInicial) : '';
    const newDataFinal = dataFinal ? new Date(dataFinal) : '';
    closeAlert();
    if (erroInputNumeroMP) {
      openAlertError('Número MP inválido.');
      return;
    }
    if (!this.isIntervaloDatasValido(newDataInicial, newDataFinal)) {
      openAlertError('A data final deve ser maior ou igual a data inicial.');
      return;
    }
    if (AMBIENTE !== 'DEV' && config.getHabilitaCaptcha() && (!captcha || captcha === 'expired')) {
      openAlertError('Marque a opção ', 'Não sou um robô.');
      return;
    }
    if (!this.validarRelatorioResolucao110(tipoRelatorio, tipoProcesso)) {
      return;
    }

    setPaginaAtual(1);
    
    await fetchPesquisaProcesso({ tipoRelatorio, numeroMP, cdOrgao, cdSituacao, parteInteressada,
      dataInicial: newDataInicial, dataFinal: newDataFinal, tipoProcesso });

    if (!exibeResultadosMesmaPagina(tipoRelatorio)) {history.push('/portaltransparencia/consulta')};
  }

  updateNumeroMP = (value) => {
    const numeroFormatado = formatarNumeroMP(value);
    this.setState({
      erroInputNumeroMP: numeroFormatado.length > 0 && numeroFormatado.length < 18,
      numeroMPFormatado: numeroFormatado,
      numeroMP: numeroFormatado.split('.').join('').split('-').join(''),
      desabilidado: numeroFormatado.length > 0,
    });
    if (numeroFormatado.length > 0) {
      this.setState({
        cdOrgao: '',
        valorOrgao: { value: '', label: 'Selecione um órgão' },
        cdSituacao: '',
        valorSituacao: { value: '', label: 'Selecione uma situação' },
        parteInteressada: '',
        dataInicial: '',
        dataFinal: '',
      });
    }
  }

  isIntervaloDatasValido = (dataInicial, dataFinal) => (dataInicial && dataFinal ? dataFinal >= dataInicial : true);

  validarRelatorioResolucao110 = (tipoRelatorio, tipoProcesso) => {
    if (tipoRelatorio === 10) {
      this.setState({ tipoProcessoSelecionado: !!tipoProcesso });
      if (!tipoProcesso) {
        return false;
      }
    }
    return true;
  }

  render() {
    const captchaRef = React.createRef();
    const { history, classes, loading } = this.props;
    const { erroInputNumeroMP, numeroMPFormatado, desabilidado, tipoRelatorio, dataInicial, dataFinal, valorOrgao, valorSituacao, parteInteressada, tipoProcessoSelecionado } = this.state;

    if (loading) {
      return <div style={{ position: 'absolute', left: '50%', top: '50%' }}><Loader size="large" color="blue" label="Carregando" /></div>;
    }

    return (
      <Fragment>
        <Alerts />
        <Grid container style={{ paddingLeft: 20, paddingRight: 20 }}>
          <div className="container-fluid">
            <div className="row">
              <div className="float-left" style={{ marginLeft: 15, marginBottom: 10 }}>
                <Typography size={21} weight="semibold">{getTextoCabecalho().textoCabecalhoGrande}</Typography>
              </div>
              <div className="float-left" style={{ marginLeft: 15, paddingTop: 8, paddingBottom: 15 }}>
                <Typography size={13} color="neutralSecondaryAlt">{getTextoCabecalho().textoCabecalhoPequeno}</Typography>
              </div>
            </div>
            <div className={classes.colorPadding}>
              <div className="row">
                {![10].includes(tipoRelatorio) && (
                <div className="col-lg-4 col-md-4 col-sm-4" style={{ paddingBottom: 15 }}>
                  <Grid item>
                    <Input
                      id="numeroMPInput"
                      name="numeroMPInput"
                      fullWidth
                      label="Número MP"
                      error={erroInputNumeroMP}
                      errorText="Número inválido"
                      placeholder="Digite o número MP"
                      value={numeroMPFormatado}
                      onChange={ev => this.updateNumeroMP(ev.target.value)}
                    />
                  </Grid>
                </div>
                )}
                {[10].includes(tipoRelatorio) && (
                <div className="col-lg-4 col-md-4 col-sm-4" style={{ paddingBottom: 15 }}>
                  <Grid item>
                    <Select
                      required
                      size="medium"
                      label="Tipo de processo"
                      options={tiposProcessos}
                      isError={!tipoProcessoSelecionado}
                      errorMessage="Campo obrigatório"
                      onChange={(ev) => { this.setState({ tipoProcesso: ev.value, tipoProcessoSelecionado: !!ev.value }); }}
                    />
                  </Grid>
                </div>
                )}
                <div className="col-lg-4 col-md-4 col-sm-4" style={{ paddingBottom: 15 }}>
                  <Grid item>
                    <Orgao
                      id="idOrgao"
                      name="idOrgao"
                      label="Órgão"
                      placeholder="Selecione um órgão"
                      onChange={(ev) => { this.setState({ cdOrgao: ev.value, valorOrgao: ev }); }}
                      disabled={desabilidado}
                      value={valorOrgao}
                    />
                  </Grid>
                </div>
                {[1, 2, 3, 4, 7].includes(tipoRelatorio) && (
                <div className="col-lg-4 col-md-4 col-sm-4" style={{ paddingBottom: 15 }}>
                  <Grid item>
                    <Situacao
                      id="idSituacao"
                      name="idSituacao"
                      label="Situação"
                      placeholder="Selecione uma situação"
                      onChange={(ev) => { this.setState({ cdSituacao: ev.value, valorSituacao: ev }); }}
                      disabled={desabilidado}
                      value={valorSituacao}
                    />
                  </Grid>
                </div>
                )}
                {![10].includes(tipoRelatorio) && (
                <div className="col-lg-4 col-md-4 col-sm-4" style={{ paddingBottom: 15 }}>
                  <Grid item>
                    <Input
                      id="parteInteressada"
                      name="parteInteressada"
                      fullWidth
                      label="Parte interessada"
                      errorText="Campo obrigatório"
                      placeholder="Digite uma parte interessada"
                      onChange={(ev) => { this.setState({ parteInteressada: ev.target.value }); }}
                      disabled={desabilidado}
                      value={parteInteressada}
                    />
                  </Grid>
                </div>
                )}
                <div className="col-lg-2 col-md-2 col-sm-2" style={{ paddingBottom: 15, minWidth: 150 }}>
                  <InputDate
                    label="Data inicial"
                    id="dataInicial"
                    name="dataInicial"
                    value={dataInicial}
                    disabled={desabilidado}
                    style={{ background: desabilidado ? '#EAEAEA' : '#FFFFFF' }}
                    max={moment(new Date(new Date().setDate(new Date().getDate() + 1))).format('YYYY-MM-DD')}
                    onChange={(ev) => { this.setState({ dataInicial: ev }); }}
                  />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2" style={{ paddingBottom: 15, minWidth: 150 }}>
                  <InputDate
                    label="Data final"
                    id="dataFinal"
                    name="dataFinal"
                    value={dataFinal}
                    disabled={desabilidado}
                    style={{ background: desabilidado ? '#EAEAEA' : '#FFFFFF' }}
                    onChange={(ev) => { this.setState({ dataFinal: ev }); }}
                  />
                </div>
                {config.getHabilitaCaptcha() && (
                <div className="col-lg-4 col-md-4 col-sm-4" style={{ paddingBottom: 15 }}>
                  <ReCAPTCHA
                    id="reCaptcha"
                    name="reCaptcha"
                    style={{ display: 'inline-block' }}
                    ref={captchaRef}
                    sitekey={SITE_KEY}
                    onChange={this.handleChange}
                  />
                </div>)}
              </div>

              <div className="row" style={{ paddingLeft: 15, paddingBottom: 15 }}>
                <Grid item>
                  <Button
                    id="btnConsultar"
                    name="btnConsultar"
                    aria-label="Consultar"
                    size="medium"
                    variant="primary"
                    onClick={this.getProcessos}
                  >Consultar
                  </Button>
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
        {exibeResultadosMesmaPagina(tipoRelatorio) && (<ConsultaProcesso history={history} />)}
      </Fragment>
    );
  }
}

PesquisaProcesso.propTypes = {
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  openAlertError: PropTypes.func.isRequired,
  closeAlert: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  fetchPesquisaProcesso: PropTypes.func.isRequired,
  fetchConfiguracoes: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  processos: state.pesquisaProcessoReducer.processos,
  loading: state.pesquisaProcessoReducer.loading ? state.pesquisaProcessoReducer.loading : false,
 });

const mapDispatchToProps = { ...actions, ...alertActions, setPaginaAtual: setPaginaAtualAction };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PesquisaProcesso));
