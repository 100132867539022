import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Stamp } from '@bayon/commons';
import Icon from '@bayon/svg-icons';
import { connect } from 'react-redux';
import AngleDown from '@bayon/svg-icons/icons/AngleDown';
import AngleUp from '@bayon/svg-icons/icons/AngleUp';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { formatarNumeroMP } from '../../../../commons/mascaras';
import * as actionConsultaProcessoCommon from '../../../common/ConsultaProcessoCommon';

const styles = () => ({
  colorPadding: {
    padding: 25,
    background: '#E4E4E4',
  },

  unjLabel: {
    marginBottom: 0,
    fontSize: '12px',
    fontWeight: 'lighter',
    color: '#919191',
    lineHeight: '20px',
  },

  caption: { fontSize: 5, backgroundColor: '#00713C' },

  fontCabecalhoCard: { fontWeight: 600 },

});

function CabecalhoProcesso(props) {
  const { classes, processo, history } = props;
  const [showCabecalho, setShowCabecalho] = useState(false);

  function showHideDiv(e) {
    e.preventDefault();
    setShowCabecalho(!showCabecalho);
  }

  function voltar(ev) {
    ev.preventDefault();
    history.goBack();
  }

  return (
    <Fragment>
      <div className="row" style={{ padding: 20 }}>
        <div>
          <Link id="linkVoltar" to="#" style={{ color: '#333333' }} onClick={ev => voltar(ev)}>
            <Icon>ArrowLeft</Icon>
            &nbsp;
            Voltar
          </Link>
        </div>
      </div>
      <div className={['row', classes.colorPadding].join(' ')}>
        <div className="col-lg-4">
          <div>
            {processo.numero_processo ? formatarNumeroMP(processo.numero_processo) : ''} &nbsp;
            <Stamp
              label={processo.descricao_situacao ? processo.descricao_situacao : ''}
              textColor="#ffffff"
              backgroundColor="#5B8FC7"
            />
          </div>
        </div>
        <div className="col-lg-2">
          <div className={classes.unjLabel} htmlFor="lbl2">Data do cadastro</div>
          {processo.data_processo && (
            <div>{moment(processo.data_processo).utc(false).format('DD/MM/YYYY HH:mm')}</div>
          )}
        </div>

        <div className="col-lg-3">
          <div htmlFor="lbl3" className={classes.unjLabel}>Tipo de cadastro</div>
          <div>{processo.tipo_processo}</div>
        </div>

        <div className="col-lg-3">
          <div htmlFor="lbl4" className={classes.unjLabel}>Órgão responsável</div>
          <div>{processo.descricao_local}</div>
        </div>
      </div>
      {showCabecalho && (
      <div className={['row', classes.colorPadding].join(' ')} style={{ paddingTop: 0 }}>
        <div className="col-lg-4" />
        <div className="col-lg-2">
          <div className={classes.unjLabel}>Comarca</div>
          <div>{processo.nome_comarca}</div>
        </div>
        <div className="col-lg-3">
          <div className={classes.unjLabel}>Município de fato</div>
          <div>{processo.nome_municipio}</div>
        </div>
        <div className="col-lg-3">
          <div className={classes.unjLabel}>Objeto</div>
          <div>{processo.descricao_objeto}</div>
        </div>
      </div>
      )}
      <div className="row" align="right" style={{ background: '#E4E4E4' }}>
        <div className="col-lg-12">
          {!showCabecalho && (
          <a href="#" id="linkMais" onClick={showHideDiv} style={{ color: '#808080' }}>
            <AngleDown style={{ height: 14, width: 14, paddingRight: 2 }} />
            Mais
          </a>
          )}
          {showCabecalho && (
          <a href="#" onClick={showHideDiv} style={{ color: '#808080' }}>
            <AngleUp style={{ height: 14, width: 14, paddingRight: 2 }} />
            Menos
          </a>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <hr style={{ marginTop: 0, marginBottom: 0, borderTop: '1px solid #D1D1D1' }} />
        </div>
      </div>
    </Fragment>
  );
}

CabecalhoProcesso.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  processo: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ processo: state.consultaProcessoReducer.processo });

const mapDispatchToProps = { ...actionConsultaProcessoCommon };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CabecalhoProcesso));
